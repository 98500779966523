body {
  font-family: 'Noto Sans JP', sans-serif;
  margin: 0;
  background-color: #d2d2d2;
  touch-action: manipulation;
}

h1 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.canvas-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: scroll;
  position: relative;
}

.japanese {
  font-size: 1em;
  margin-top: 0;
}

.controller {
  position: absolute;
}

.tool-box {
  position: fixed;
  z-index: 1001;
}

.canvas-controller {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}
