.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.loading p {
  font-size: 2rem;
}

.loading img {
  width: 40%;
  height: auto;
}
